body,
html {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[data-app-list='root-horizontal'] {
  scroll-snap-type: x mandatory;
}

[data-app-list='page'] {
  scroll-snap-align: start;
}

[data-app-chart-animated-tooltip='1'] {
  backface-visibility: hidden;
}

[data-app-button^='button-'] {
  overflow: hidden;
  cursor: pointer;
}

[aria-disabled='true'] {
  pointer-events: none !important;
}

[data-app-button^='button-']:hover {
  transition:
    background-color 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
}

/* ButtonVariant - Primary */
[data-app-button='button-Primary-color-Default'] {
  transition: all 0.2s ease-in-out;
}

[data-app-button='button-Primary-color-Default']:not(
    [aria-disabled='true']
  ):hover {
  background-color: var(--primary) !important;
}

[data-app-button='button-Primary-color-Default'][aria-disabled='true'] {
  cursor: not-allowed !important;
}

/* ButtonVariant - Orange ButtonColor - White */
[data-app-button='button-Orange-color-White'] {
  transition: all 0.2s ease-in-out;
}

[data-app-button='button-Orange-color-White']:hover {
  background-color: var(--info) !important;
}

/* ButtonVariant - PrimaryLight */
[data-app-button='button-PrimaryLight-color-Secondary']::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--additional4) !important;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: -1;
}

[data-app-button='button-PrimaryLight-color-Secondary']:hover::before {
  opacity: 1;
}

[data-app-button='button-PrimaryLight-color-Secondary']:hover > div {
  color: var(--primary) !important;
}

[data-app-button='button-PrimaryLight-color-Secondary']:hover path[fill] {
  fill: var(--primary) !important;
}

[data-app-button='button-PrimaryLight-color-Secondary']:hover path[stroke] {
  stroke: var(--primary) !important;
}

[data-app-button='button-PrimaryLight-color-Secondary']:active {
  opacity: 0.5 !important;
}

/* ButtonVariant - Highlighted */
[data-app-button='button-Highlighted-color-Secondary']::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    270deg,
    var(--primary) -2.8%,
    var(--orangeGradientEnd) 104.21%
  );
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: -1;
}

[role='button'] {
  cursor: pointer;
}

[data-app-button='button-Highlighted-color-Secondary'] path {
  transition: fill 0.2s ease-in-out;
}

[data-app-button='button-Highlighted-color-Secondary'] > div {
  transition: color 0.2s ease-in-out;
}

[data-app-button='button-Highlighted-color-Secondary']:hover::before {
  opacity: 1;
}

[data-app-button='button-Highlighted-color-Secondary']:hover > div {
  color: var(--whitey) !important;
}

[data-app-button='button-Highlighted-color-Secondary']:active {
  opacity: 0.5 !important;
}

/* ButtonVariant - SecondaryLight */
[data-app-button='button-SecondaryLight-color-Secondary']::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--additional4);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: -1;
}

[data-app-button='button-SecondaryLight-color-Secondary'] path {
  transition: fill 0.2s ease-in-out;
}

[data-app-button='button-SecondaryLight-color-Secondary'] > div {
  transition: color 0.2s ease-in-out;
}

[data-app-button='button-SecondaryLight-color-Secondary']:hover::before {
  opacity: 1;
}

[data-app-button='button-SecondaryLight-color-Secondary']:hover > div {
  color: var(--primary) !important;
}

[data-app-button='button-SecondaryLight-color-Secondary']:active {
  opacity: 0.5 !important;
}

[data-app-button='button-SecondaryLight-color-Secondary']:hover path {
  fill: var(--primary) !important;
}

/* ButtonVariant - Text ButtonColor - Secondary */

[data-app-button='button-Text-color-Secondary'] path {
  transition: fill 0.2s ease-in-out;
}

[data-app-button='button-Text-color-Secondary'] > div {
  transition: color 0.2s ease-in-out;
}

[data-app-button='button-Text-color-Secondary']:hover::before {
  opacity: 1;
}

[data-app-button='button-TabletDefault-color-Secondary']:hover
  svg
  path:not(:first-child) {
  stroke: var(--whitey) !important;
}

[data-app-button='button-Text-color-Secondary']:hover > div {
  color: var(--primary) !important;
}

[data-app-button='button-Text-color-Secondary']:active {
  opacity: 0.5 !important;
}

[data-app-button='button-Text-color-Secondary']:hover path {
  fill: var(--primary) !important;
  stroke: var(--primary) !important;
}

[data-app-button='button-Text-color-Secondary']:hover
  path[stroke='currentColor'] {
  fill: transparent !important;
  stroke: var(--primary) !important;
}
/* ButtonVariant - Text ButtonColor-Default */
[data-app-button='button-Text-color-Default'] {
  transition: color 0.2s ease-in-out;
}

[data-app-button='button-Text-color-Default']:hover {
  color: var(--info) !important;
}

[data-app-button='button-Text-color-Default'] > div {
  transition: color 0.2s ease-in-out;
}

[data-app-button='button-Text-color-Default']:hover > div {
  color: var(--info) !important;
}

[data-app-button='button-Text-color-Default']:active {
  opacity: 0.5 !important;
}

/* ButtonVariant - TabletDefault */

[data-app-button='button-TabletDefault-color-Primary']:hover {
  background-color: transparent;
}

[data-app-button='button-TabletDefault-color-Primary']:active {
  opacity: 0.5;
}

[data-app-button='button-TabletDefault-color-Primary']::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--primary);
  border: 1px solid var(--whitey);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  box-sizing: border-box;
  border-radius: 6px;
  z-index: -1;
}

[data-app-button='button-TabletDefault-color-Primary'] > div {
  transition: fill 0.2s ease-in-out;
}

[data-app-button='button-TabletDefault-color-Primary']:hover::before {
  opacity: 1;
}

[data-app-button='button-TabletDefault-color-Primary']:hover path {
  fill: var(--whitey) !important;
}

[data-app-button='button-TabletDefault-color-Primary']:hover
  svg
  path:not(:first-child) {
  stroke: var(--whitey) !important;
}

[data-app-button='button-TabletDefault-color-Primary']:hover > div {
  color: var(--whitey) !important;
}

[data-app-button='button-TabletDefault-color-Secondary'] {
  transition: background-color 0.2s ease-in-out;
}

[data-app-button='button-TabletDefault-color-Secondary']:hover {
  background-color: transparent;
}

[data-app-button='button-TabletDefault-color-Secondary']:active {
  opacity: 0.5;
}

[data-app-button='button-TabletDefault-color-Secondary']::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: 1px solid var(--whitey);
  box-sizing: border-box;
  border-radius: 6px;
  height: 100%;
  background: var(--blueGradientStart);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: -1;
}

[data-app-button='button-TabletDefault-color-Secondary'] > div {
  transition: fill 0.2s ease-in-out;
}

[data-app-button='button-TabletDefault-color-Secondary']:hover::before {
  opacity: 1;
}

[data-app-button='button-TabletDefault-color-Secondary']:hover path {
  fill: var(--whitey) !important;
}

[data-app-button='button-TabletDefault-color-Secondary']:hover > div {
  color: var(--whitey) !important;
}

[data-app-button='button-SecondaryLight-color-Default']:hover {
  background-color: var(--additional3) !important;
}

[data-app-button='button-SecondaryLight-color-Default']:active {
  opacity: 0.5;
}

/*currency-item*/

[data-app='currency-item'] > div:first-child {
  transition: all 0.2s ease-in-out;
}

[data-app='currency-item']:hover > div:first-child {
  border-color: var(--primary) !important;
  background-color: var(--additional4) !important;
}

/*button-Default-color-Default*/

[data-app-button='button-Default-color-Default'] svg path {
  transition: fill 0.2s ease-in-out;
}

[data-app-button='button-Default-color-Default']:hover svg path {
  stroke: var(--primary) !important;
}

[data-app-button='button-Default-color-Default']:active svg path {
  opacity: 0.5;
}

/*history-filter-item*/

[data-app='history-filter-item'] div {
  transition: all 0.2s ease-in-out;
}

[data-app='history-filter-item']:hover div {
  border-color: var(--primary) !important;
  background-color: var(--additional4) !important;
}

[data-app='history-filter-item'] > div > div > div:last-child path {
  transition: all 0.2s ease-in-out;
}

[data-app='history-filter-item']:hover > div > div > div > div:last-child path {
  stroke: var(--primary) !important;
}

/*filter-item*/

[data-app='filter-item'] {
  transition: all 0.2s ease-in-out;
}

[data-app='filter-item']:hover {
  border-color: var(--primary) !important;
  background-color: var(--additional4) !important;
}

[data-app='filter-item'] > div:first-child > div {
  transition: all 0.2s ease-in-out;
}

[data-app='filter-item']:hover > div:first-child > div {
  border-color: var(--primary) !important;
}

/*setting-item*/

[data-app='setting-item'] > div svg path,
[data-app='setting-item'] div {
  transition: all 0.2s ease-in-out;
}

[data-app='setting-item']:hover > div svg path,
[data-app='setting-item']:hover div {
  color: var(--primary) !important;
}

[data-app='setting-item'][aria-disabled='true'] {
  pointer-events: none !important;
  opacity: 0.5 !important;
}

/*calendar-item*/

[data-app='calendar-item'] > div {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: var(--uiPrimary);
  border-radius: 25px;
  overflow: hidden;
}

*::-webkit-scrollbar-thumb {
  background: var(--uiSecondary);
  border: 3px solid transparent;
  border-radius: 5px;
}

@keyframes launcherExit {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes mainEnter {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  15% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

#launcher {
  will-change: transform, opacity;
  animation: launcherExit 1s;
}

#main {
  will-change: transform, opacity;
  animation: mainEnter 1s;
}

.social-btns > .social-btn {
  margin-right: 0;
  margin-bottom: 5px;
  transition: all 0.3s;
}

.social-btns > .social-btn_fb,
.social-btns > .social-btn_eml {
  display: none;
}
